import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import toast, { Toaster } from "react-hot-toast";
import InputArt from './InputArt';
import InputSelect from './InputSelect';
import DatePickerField from './DatePicker';
import Col from 'react-bootstrap/Col';
// import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Radio, useRadioState } from "pretty-checkbox-react";
import axios from "../AxiosConfig";
import choicesLandkreise from '../../data/gadm/choices_landkreis_bayern.json';
import choicesNaturraumHauptname from '../../data/naturraum/choices_naturraum_hauptname_bayern.json';
import choicesNaturraum from '../../data/naturraum/choices_naturraum_bayern.json';
import choicesTK25 from '../../data/tk25/choices_tk25_bayern.json';
import choicesStadium from "../../data/choices_stadium.json";
import choicesMonate from "../../data/choices_monat.json";
import choicesBundesland from "../../data/gadm/choices_bundesland.json";
import choicesRegierungsbezirk from "../../data/gadm/choices_regierungsbezirk_bayern.json";
// import choicesBestimmungssicherheit from "../../data/choices_bestimmungssicherheit.json";
import choicesArtDerBeobachtung from "../../data/choices_art_der_beobachtung.json";
import choicesBeleg from "../../data/choices_beleg.json";
import config from '../../config/index.js';
import { YearInput } from './YearInput.js';
import { createQuery } from '../Beobachtungen/createQuery.js';

const choicesPraxisgruppe = config.PRAXISGRUPPE;


function FiltersPrivate(props) {

  // const [choicesBeobachter, setChoicesBeobachter] = useState([]);
  const [choicesFundort, setChoicesFundort] = useState([]);
  const [choicesOrt, setChoicesOrt] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // reference to formik form
  const formRef = useRef();

  const radioExport = useRadioState({ state: "Auswahl" });

  const getChoices = async () => {
    // let response = await axios.get(`beobachter`);
    // const beobachter = response.data.map((row) => {
    //   return ({ value: row.beobachter, label: row.beobachter })
    // });
    // setChoicesBeobachter(beobachter);
    let response = await axios.get(`user/fundort`);
    const fundort = response.data.map((row) => {
      return ({ value: row.fundort, label: row.fundort })
    });
    setChoicesFundort(fundort);
    response = await axios.get(`user/ort`);
    const ort = response.data.map((row) => {
      return ({ value: row.ort, label: row.ort })
    });
    setChoicesOrt(ort);
  };

  const prepareInputs = (values) => {
    let jahr_min = values.jahr_min === "" ? 1800 : values.jahr_min;
    let jahr_max = values.jahr_max === "" ? dayjs().year() : values.jahr_max;
    let datum_von = values.datum_von === null ? '1800-01-01' : dayjs(values.datum_von).format("YYYY-MM-DD");
    let datum_bis = values.datum_bis === null ? new Date() : values.datum_bis;
    let created_von = values.created_von === null ? '1800-01-01' : dayjs(values.created_von).format("YYYY-MM-DD");
    let created_bis = values.created_bis === null
      ? dayjs().add(1, 'day').format("YYYY-MM-DD")
      : dayjs(values.created_bis).add(1, 'day').format("YYYY-MM-DD");

    const filterValues = {
      art: values.art !== null ? [values.art] : [], // values.art is an Object
      jahr: [jahr_min, jahr_max],
      bundesland: values.bundesland,
      ort: values.ort,
      fundort: values.fundort,
      // beobachter: values.beobachter,
      regierungsbezirk: values.regierungsbezirk,
      naturraum_hauptname: values.naturraum_hauptname,
      naturraum_name: values.naturraum_name,
      tk25: values.tk25,
      landkreis: values.landkreis,
      monat: values.monat,
      stadium: values.stadium,
      praxisgruppe: values.praxisgruppe,
      art_der_beobachtung: values.art_der_beobachtung,
      beleg: values.beleg,
      datum: [datum_von, dayjs(datum_bis).format("YYYY-MM-DD")],
      created_at: [created_von, created_bis]
    }

    return filterValues;
  }

  const getObservationsDownload = async (values) => {
    console.log("download");
    let url = 'user/export';

    const filters = prepareInputs(formRef.current.values);

    if (radioExport.state === "Auswahl") {
      console.log(filters);
      const query = createQuery(filters, props.filtersSelect, ["datum", "created_at"]);
      console.log(query);
      url = url.concat(query);
    }

    const axiosPromise = axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'beobachtungen.xlsx');
      document.body.appendChild(link);
      link.click();
      closeModal();
    });

    toast.promise(axiosPromise, {
      loading: 'Beobachtungen werden heruntergeladen.',
      success: 'Beobachtungen heruntergeladen.',
      error: 'Ein Fehler ist aufgetreten.',
    });
  };

  const openModal = () => {
    setShowDropdown(false);
    setModalStatus(true);
  };

  const closeModal = () => {
    setModalStatus(false);
  };

  const toggleDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  }

  useEffect(() => {
    getChoices();
  }, []);

  return (
    <Formik
      innerRef={formRef}

      initialValues={{
        stadium: [],
        datum_von: null,
        datum_bis: null,
        monat: [],
        regierungsbezirk: [],
        landkreis: [],
        tk25: [],
        fundort: [],
        ort: [],
        // beobachter: [],
        beleg: [],
        art_der_beobachtung: [],
        naturraum_hauptname: [],
        naturraum_name: [],
        bundesland: [],
        jahr_min: "",
        jahr_max: "",
        praxisgruppe: [],
        art: null,
        created_von: null,
        created_bis: null
      }}

      onSubmit={
        (values, { resetForm }) => {
          const filterValues = prepareInputs(values);
          props.handleSubmit(filterValues);
        }
      }
    >
      {
        props => {
          const {
            values,
            // handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          } = props;
          return (
            <>
              <Toaster position="bottom-right" />
              <Form onSubmit={handleSubmit}>
                <Row className='justify-content-md-left'>
                  <Form.Group as={Col} xs={12} sm={12} md={4} lg={4} className="mb-2">
                    <InputArt name="art" onChange={handleSubmit}></InputArt>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} sm={6} md={4} className="mb-2">
                    <InputSelect
                      name="ort"
                      title={false}
                      menuPortalTarget={document.body}
                      placeholder="Ort"
                      choices={choicesOrt}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={6} md={2} className='d-md-block mb-2'>
                    <Dropdown onSelect={(open) => console.log(open)} onToggle={toggleDropdown} show={showDropdown}>
                      <Dropdown.Toggle className="w-100" variant="secondary">
                        Mehr Filter
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <div className="dropdown-wide">
                          <div style={{ padding: "0 10px" }}>
                            <Row>
                              <Form.Group as={Col} md="6" className="dropdown-filter-column-left">
                                <InputSelect
                                  name="bundesland"
                                  choices={choicesBundesland}
                                />
                                <InputSelect
                                  name="regierungsbezirk"
                                  choices={choicesRegierungsbezirk}
                                />
                                <InputSelect
                                  name="landkreis"
                                  choices={choicesLandkreise}
                                />
                                <InputSelect
                                  name="naturraum_hauptname"
                                  title="Naturraum Hauptname"
                                  choices={choicesNaturraumHauptname}
                                />
                                <InputSelect
                                  name="naturraum_name"
                                  title="Naturraum"
                                  choices={choicesNaturraum}
                                />
                                <InputSelect
                                  name="tk25"
                                  title="TK25"
                                  choices={choicesTK25}
                                />
                                <InputSelect
                                  name="fundort"
                                  choices={choicesFundort}
                                />
                                <hr></hr>
                                <Button variant="link" onClick={openModal}>
                                  <i className="fa fa-download" role="presentation" aria-label="download icon"></i>{" "}
                                  Beobachtungen herunterladen
                                </Button>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <InputSelect
                                  name="praxisgruppe"
                                  choices={choicesPraxisgruppe}
                                />
                                <InputSelect
                                  name="stadium"
                                  choices={choicesStadium}
                                />
                                <InputSelect
                                  name="art_der_beobachtung"
                                  choices={choicesArtDerBeobachtung}
                                  title="Art der Beobachtung"
                                />
                                <InputSelect
                                  name="beleg"
                                  choices={choicesBeleg}
                                />
                                {/* <InputSelect
                              name="beobachter"
                              choices={choicesBeobachter}
                            /> */}
                                <Form.Text>Jahr</Form.Text>
                                <Row>
                                  <Form.Group as={Col} md="6">
                                    <YearInput
                                      id="jahr_min"
                                      value={values.jahr_min}
                                      placeholder="Jahr von"
                                      setFieldValue={setFieldValue}
                                      handleBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className="ps-0">
                                    <YearInput
                                      id="jahr_max"
                                      value={values.jahr_max}
                                      placeholder="Jahr bis"
                                      setFieldValue={setFieldValue}
                                      handleBlur={handleBlur}
                                    />
                                  </Form.Group>
                                </Row>
                                <InputSelect
                                  name="monat"
                                  choices={choicesMonate}
                                />
                                <Form.Text>Datum der Beobachtung</Form.Text>
                                <Row>
                                  <Form.Group as={Col} md="6">
                                    <DatePickerField
                                      name="datum_von"
                                      placeholderText="Datum von..."
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className="ps-0">
                                    <DatePickerField
                                      name="datum_bis"
                                      placeholderText="Datum bis..."
                                    />
                                  </Form.Group>
                                </Row>
                                <Form.Text>Hinzugefügt am</Form.Text>
                                <Row>
                                  <Form.Group as={Col} md="6">
                                    <DatePickerField
                                      name="created_von"
                                      placeholderText="Datum von..."
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className="ps-0">
                                    <DatePickerField
                                      name="created_bis"
                                      placeholderText="Datum bis..."
                                    />
                                  </Form.Group>
                                </Row>
                              </Form.Group>
                            </Row>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={6} md={2} className="d-md-block mb-2">
                    <Button variant="success" type="submit" className="w-100">Daten abfragen</Button>
                  </Form.Group>
                </Row>
              </Form>
              <Modal show={modalStatus} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Beobachtungen exportieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Hier können entweder die aktuell durch die Filter ausgewählten oder alle Beobachtungen
                    als Excel Datei heruntergeladen werden.
                  </p>
                  <Radio color="primary" name="export" value="Auswahl" {...radioExport}>Ausgewählte Beobachtungen</Radio>
                  <Radio color="primary" name="export" value="Gesamt" {...radioExport}>Alle meine Beobachtungen</Radio>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    Abbrechen
                  </Button>
                  <Button variant="primary" onClick={(values) => getObservationsDownload(values)}>
                    Download
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
        }
      }
    </Formik>
  )
}

export default FiltersPrivate;
