import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from "../AxiosConfig";
import Filters from '../Inputs/Filters';
import Map from '../Map/Map';
import Fotogalerie from './Fotogalerie';
import { Phenogram } from './Phenogram';
import { useRadioState } from 'pretty-checkbox-react';
import { orderBy } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../Loading.js';
import { createQuery } from './createQuery.js';
import config from '../../config/index.js';
import { Button } from 'react-bootstrap';
import StatsBadge from '../StatsBadge.js';
import ObservationTabs from './ObservationTabs';

function Beobachtungen() {

  // state contains data
  const [observations, setObservations] = useState([]);
  const [loadedData, setLoadedData] = useState([]);
  const [observationsAtlas, setObservationsAtlas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [activeKey, setActiveKey] = useState("tabelle");

  const radio = useRadioState({ state: "datum" });
  const aufsteigend = useRadioState({ state: "desc" });

  const getObservations = async (query) => {
    setLoading(true);
    const response = await axios.get(`observations/get`.concat(query));
    setObservations(response.data);
    setLoadedData(response.data);
    setLoading(false);
  };

  const getObservationsAtlas = async (query) => {
    const response = await axios.get(`observations/atlas`.concat(query));
    setObservationsAtlas(response.data);
  };

  const getLastObservations = async () => {
    setLoading(true);
    const response = await axios.get(`observations/get?bundesland=${config.BUNDESLAND}&artengruppe=${config.ARTENGRUPPE}&limit=200`);
    setObservations(response.data);
    setLoadedData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    console.log("useEffect");
    getLastObservations();
  }, []);

  // this function changes state on button click "Daten abfragen"
  const handleSubmit = filters => {
    console.log(filters);

    // define possible filters: This needs to match the available inputs in Filters!
    const filtersSelect = [
      "art",
      "praxisgruppe",
      "stadium",
      "landkreis",
      "bundesland",
      "regierungsbezirk",
      "naturraum_hauptname",
      "tk25",
      "bestimmungssicherheit",
      "monat"
    ];

    const query = createQuery(filters, filtersSelect, ["datum"]);
    console.log(query);

    getObservations(query);
    if (config.BUNDESLAND === "Bayern" &&
      filters["jahr"][0] === 1800 &&
      filters["art"] !== null &&
      filters["art"].length > 0) {
      getObservationsAtlas(query);
    } else {
      setObservationsAtlas([]);
    }
  }

  // Create array with only one object per observation id
  let id = [];
  const uniqueObservations = observations.filter((observation) => {
    if (!id.includes(observation.id)) {
      id.push(observation.id);
      return (observation)
    } else {
      return null;
    }
  });

  console.log(uniqueObservations);
  const observationsSorted = orderBy(uniqueObservations, radio.state, aufsteigend.state);
  console.log(observationsSorted);

  const uniqueObservationsPhenogram = uniqueObservations.filter((row) => row.datum && row.datum.length > 4);

  const observationsWithImages = observations.filter(function (observation) {
    return (observation.foto !== undefined && observation.foto !== null)
  });
  // const observationsWithImages = observations.filter(function(observation) {
  //     return(observation.foto !== null)
  // });
  const nObservations = uniqueObservations.length;
  const nTK25 = [...new Set(uniqueObservations.map((x) => x.tk25))].length;
  const nArt = [...new Set(uniqueObservations.map((x) => x.art))].length;

  const filterTK25 = (tk25Quadrant) => {
    console.log(tk25Quadrant);
    const current = observations.filter(function (observation) {
      return (observation.tk25_quadrant === tk25Quadrant)
    });
    setObservations(current);
    setShowButton(true);
    setActiveKey("tabelle");
  };

  const cancelFilterTK25 = () => {
    setObservations(loadedData);
    setShowButton(false);
  };

  return (
    <Container>
      <Toaster position="bottom-right" />
      <br></br>
      <h2>
        Beobachtungen
        {showButton ?
          <Button
            onClick={cancelFilterTK25}
            variant="outline-secondary"
            style={{ marginLeft: "10px" }}
            size="sm"
          >
            <i className="fa fa-remove"></i> TK25 Filter löschen
          </Button> : null}
        <StatsBadge nArt={nArt} nObservations={nObservations} nTK25={nTK25} />
      </h2>
      <hr></hr>
      <Row>
        <Col xs={12}>
          <Filters handleSubmit={handleSubmit} ></Filters>
        </Col>
      </Row>
      <hr></hr>
      <Tabs
        activeKey={activeKey}
        transition={false}
        id="tabs-beobachtungen"
        onSelect={(activeKey) => setActiveKey(activeKey)}
      >
        <Tab eventKey="tabelle" title="Beobachtungsdaten">
          {loading ?
            <Loading /> :
            <ObservationTabs
              observationsSorted={observationsSorted}
              radio={radio}
              aufsteigend={aufsteigend}
              showOrt={false}
            />
          }
        </Tab>
        <Tab eventKey="karte" title="Verbreitungskarte">
          <Map
            observations={uniqueObservations}
            observationsAtlas={observationsAtlas}
            filterTK25={filterTK25}
            cancelFilterTK25={cancelFilterTK25}
            showButton={showButton}
          >
          </Map>
        </Tab>
        <Tab eventKey="flugzeit" title="Flugzeit">
          <Phenogram observations={uniqueObservationsPhenogram} />
        </Tab>
        <Tab eventKey="fotos" title="Fotos">
          <Fotogalerie
            observations={observationsWithImages}
          />
        </Tab>
      </Tabs>
      <br></br>
      <Container className="panel">
        <h4>Hinweise zur Benutzung</h4>
        <p>Es werden zunächst die neuesten Beobachtungen angezeigt.
          Über die Art- und Jahresauswahl sowie die weiteren Filter können weitere Datensätze geladen werden,
          dafür auf den grünen "Daten abfragen" Button klicken.
          Standardmäßig werden nur Datensätze aus {config.BUNDESLAND} angezeigt. Wenn der Filter bei Bundesland herausgelöscht wird,
          werden auch Datensätze aus anderen Bundesländern auf der Karte dargestellt.
          Beim Anklicken der Verbreitungspunkte wird pro Beobachter, Quadrant und Jahr nur eine Beobachtung angezeigt.
        </p>
      </Container>
      <br></br>
    </Container>
  )
}

export default Beobachtungen;
