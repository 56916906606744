import dayjs from "dayjs";
import config from '../../config/index.js';

// Create query for API request based on selected filters
export const createQuery = (filters, filterVariables, dateFilters) => {
  console.log(filters);
  let query = `?artengruppe=${config.ARTENGRUPPE}`;

  // add selectInputs to API query
  for (let variable of filterVariables) {
    if (filters[variable] !== null && filters[variable].length > 0) {
      let newQuery = filters[variable].map(a => `${variable}=${a.value}`).join("&");
      console.log(newQuery);
      query = query.concat(`&${newQuery}`);
    }
  }

  // add range inputs to API query
  for (let variable of dateFilters) {
    if (filters[variable][0] !== "1800-01-01" || filters[variable][1] < dayjs().format("YYYY-MM-DD")) {
      query = query.concat(`&${variable}=${filters[variable][0]}&${variable}=${filters[variable][1]}`);
    }  
  }

  for (let variable of ["jahr"]) {
    query = query.concat(`&${variable}=${filters[variable][0]}&${variable}=${filters[variable][1]}`);
  }

  return query;
}
