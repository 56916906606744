import React, { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from "../AxiosConfig";
import MapPrivate from '../Map/MapPrivate';
import Fotogalerie from './Fotogalerie';
import Altitude from './Altitude';
import { Phenogram } from './Phenogram';
import { useRadioState } from 'pretty-checkbox-react';
import { orderBy } from 'lodash';
import Loading from '../Loading.js';
import config from '../../config/index.js';
import StatsBadge from '../StatsBadge.js';
import { createQuery } from './createQuery.js';
import ObservationTabs from './ObservationTabs';


export default function BeobachtungenPrivate({ title, FilterComponent, filtersSelect, admin, apiEndpoint }) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ansicht = searchParams.get('ansicht');

  const [observations, setObservations] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [loadedData, setLoadedData] = useState([]);
  const radio = useRadioState({ state: "created_at" });
  const aufsteigend = useRadioState({ state: "desc" });
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [showButtonMap, setShowButtonMap] = useState(false);
  const [activeKey, setActiveKey] = useState(ansicht || "tabelle");

  const getObservations = async (query) => {
    console.log("getObservations");
    console.log(query);
    setLoading(true);
    const response = await axios.get(`${apiEndpoint}/observations`.concat(query));
    let data = response.data;
    setLoadedData(response.data);
    if (bounds !== null) {
      const north = bounds["_northEast"]["lat"];
      const east = bounds["_northEast"]["lng"];
      const south = bounds["_southWest"]["lat"];
      const west = bounds["_southWest"]["lng"];

      data = data.filter((observation) => {
        return (
          observation.latitude > south && observation.latitude < north &&
          observation.longitude > west && observation.longitude < east
        )
      });
    }

    setObservations(data);
    setLoading(false);
  };

  useEffect(() => {
    console.log("useEffect getLastObservations");
    const getLastObservations = async () => {
      setLoading(true);
      const response = await axios.get(`${apiEndpoint}/observations?artengruppe=${config.ARTENGRUPPE}&limit=200`);
      setObservations(response.data);
      setLoadedData(response.data);
      console.log(response.data);
      setLoading(false);
    };
    getLastObservations();
  }, [apiEndpoint]);

  // this function changes state on button click "Daten abfragen"
  const handleSubmit = filters => {
    console.log("handleSubmit form data -> getObservations");
    const query = createQuery(filters, filtersSelect, ["datum", "created_at"]);
    console.log(query)
    getObservations(query);
  }

  // Create array with only one object per observation id
  let id = [];
  const uniqueObservations = observations.filter((observation) => {
    if (!id.includes(observation.id)) {
      id.push(observation.id);
      return (observation)
    } else {
      return null;
    }
  });

  const observationsSorted = orderBy(uniqueObservations, radio.state, aufsteigend.state);
  const nObservations = uniqueObservations.length;
  const nTK25 = [...new Set(uniqueObservations.map((x) => x.tk25))].length;
  const nArt = [...new Set(uniqueObservations.map((x) => x.art))].length;
  const nBeobachter = [...new Set(uniqueObservations.map((x) => x.beobachter))].length;

  const filterTK25 = (tk25Quadrant) => {
    console.log("filterTK25", tk25Quadrant);
    const current = observations.filter(function (observation) {
      return (observation.tk25_quadrant === tk25Quadrant)
    });
    setObservations(current);
    setShowButton(true);
    setActiveKey("tabelle");
  };

  const cancelFilterTK25 = () => {
    console.log("cancelFilterTK25");
    setObservations(loadedData);
    setShowButton(false);
  };

  const filterMapBounds = useCallback((bounds) => {
    console.log("filterMapBounds");
    const north = bounds["_northEast"]["lat"];
    const east = bounds["_northEast"]["lng"];
    const south = bounds["_southWest"]["lat"];
    const west = bounds["_southWest"]["lng"];

    let obs = loadedData.filter((observation) => {
      return (
        observation.latitude > south && observation.latitude < north &&
        observation.longitude > west && observation.longitude < east
      )
    });
    setObservations(obs);
    setShowButtonMap(true);
  }, [loadedData]); // Add any dependencies here

  return (
    <Container>
      <br></br>
      <h2>
        {title}
        {showButton ?
          <Button
            onClick={cancelFilterTK25}
            variant="outline-secondary"
            style={{ marginLeft: "10px" }}
            size="sm"
          >
            <i className="fa fa-remove"></i> TK25 Filter löschen
          </Button> : null}
        <StatsBadge nObservations={nObservations} nTK25={nTK25} nArt={nArt} nBeobachter={nBeobachter} />
      </h2>
      <hr></hr>
      <Row>
        <Col xs={12}>
          <FilterComponent handleSubmit={handleSubmit} observations={uniqueObservations} filtersSelect={filtersSelect}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs
            activeKey={activeKey}
            transition={false}
            id="tabs-beobachtungen"
            onSelect={(activeKey) => {
              setActiveKey(activeKey);
              navigate(`?ansicht=${activeKey}`, { replace: true });
            }}
          >
            <Tab eventKey="tabelle" title="Beobachtungsdaten">
              {loading ?
                <Loading /> :
                <ObservationTabs
                  observationsSorted={observationsSorted}
                  radio={radio}
                  aufsteigend={aufsteigend}
                  showOrt={true}
                />
              }
            </Tab>
            <Tab eventKey="karte" title="Verbreitungskarte">
              <MapPrivate
                allObservations={loadedData}
                observations={uniqueObservations}
                setObservations={setObservations}
                admin={admin}
                filterTK25={filterTK25}
                cancelFilterTK25={cancelFilterTK25}
                // cancelFilterMapBounds={cancelFilterMapBounds}
                filterMapBounds={filterMapBounds}
                showButton={showButton}
                showButtonMap={showButtonMap}
                setShowButtonMap={setShowButtonMap}
                bounds={bounds}
                setBounds={setBounds}
              />
            </Tab>
            <Tab eventKey="hoehe" title="Höhenverbreitung">
              <Altitude observations={uniqueObservations} />
            </Tab>
            <Tab eventKey="flugzeit" title="Flugzeit">
              <Phenogram observations={uniqueObservations} />
            </Tab>
            <Tab eventKey="fotos" title="Fotos">
              <Fotogalerie
                observations={observations}
                private={true}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <br></br>
    </Container>
  )
}
