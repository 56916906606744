import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { pick, uniqBy } from "lodash";
import InputArt from './InputArt';
import InputSelect from './InputSelect';
import DatePickerField from './DatePicker';
import axios from "../AxiosConfig";
import choicesTK25Deutschland from '../../data/tk25/choices_tk25_deutschland.json';
import choicesTK25DeutschlandNested from '../../data/tk25/choices_tk25_deutschland_nested.json';
import choicesLandkreiseDeutschland from '../../data/gadm/choices_landkreis_deutschland.json';
import choicesLandkreiseDeutschlandNested from '../../data/gadm/choices_landkreis_deutschland_nested.json';
import choicesRegierungsbezirkDeutschlandNested from '../../data/gadm/choices_regierungsbezirk_deutschland_nested.json';
import choicesStadium from "../../data/choices_stadium.json";
import choicesMonate from "../../data/choices_monat.json";
import choicesBundesland from "../../data/gadm/choices_bundesland.json";
import choicesArtDerBeobachtung from "../../data/choices_art_der_beobachtung.json";
import choicesBeleg from "../../data/choices_beleg.json";
import choicesBestimmungsmethode from "../../data/choices_bestimmungsmethode.json";
import choicesDatenquelle from "../../data/choices_datenquelle.json";
import choicesBestimmungssicherheit from "../../data/choices_bestimmungssicherheit.json";
import config from '../../config/index.js';
import { YearInput } from './YearInput.js';

const choicesPraxisgruppe = config.PRAXISGRUPPE;
const choicesTK25Bundesland = config.CHOICESTK25;
const choicesLandkreiseBundesland = config.CHOICESLANDKREIS;
const choicesRegierungsbezirkBundesland = config.CHOICESREGIERUNGSBEZIRK;
const choicesNaturraumHauptname = config.CHOICESNATURRAUMHAUPTNAME;
const choicesNaturraum = config.CHOICESNATURRAUM;

function FiltersAdmin(props) {

  const [choicesBeobachter, setChoicesBeobachter] = useState([]);
  const [choicesErfasser, setChoicesErfasser] = useState([]);
  const [choicesFundort, setChoicesFundort] = useState([]);
  const [choicesOrt, setChoicesOrt] = useState([]);

  const [choicesTK25, setChoicesTK25] = useState(choicesTK25Bundesland);
  const [choicesLandkreise, setChoicesLandkreise] = useState(choicesLandkreiseBundesland);
  const [choicesRegierungsbezirk, setChoicesRegierungsbezirk] = useState(choicesRegierungsbezirkBundesland);

  // Function to filter object of arrays and return flat unique array
  const pickValues = (choices, selected) => {
    return (
      uniqBy(
        Object.values(
          pick(choices, selected)
        ).flat(),
        "value"
      )
    );
  }

  const filterChoices = async (selected) => {
    if (selected.length > 0) {
      const bundeslandSelected = selected.map((i) => i.value);
      // Filter all TK25 to include only the selected Bundeslaender
      // Remove duplicated TK25 entries (e.g. 5727 which is in two Bundeslaender)
      setChoicesTK25(
        pickValues(choicesTK25DeutschlandNested, bundeslandSelected)
      );

      setChoicesLandkreise(
        pickValues(choicesLandkreiseDeutschlandNested, bundeslandSelected)
      );

      setChoicesRegierungsbezirk(
        pickValues(choicesRegierungsbezirkDeutschlandNested, bundeslandSelected)
      );
    } else {
      setChoicesTK25(choicesTK25Deutschland);
      setChoicesLandkreise(choicesLandkreiseDeutschland);
      setChoicesRegierungsbezirk(choicesRegierungsbezirkBundesland);
    }
  };

  const getChoices = async () => {
    let response = await axios.get(`admin/beobachter?bundesland=${config.BUNDESLAND}`);
    const beobachter = response.data.map((row) => {
      return ({ value: row.beobachter, label: row.beobachter })
    });
    setChoicesBeobachter(beobachter);
    response = await axios.get(`admin/erfasser?bundesland=${config.BUNDESLAND}`);
    const erfasser = response.data.map((row) => {
      return ({ value: row.erfasser, label: row.erfasser })
    });
    setChoicesErfasser(erfasser);
    response = await axios.get(`admin/fundort?bundesland=${config.BUNDESLAND}`);
    const fundort = response.data.map((row) => {
      return ({ value: row.fundort, label: row.fundort })
    });
    setChoicesFundort(fundort);
    response = await axios.get(`admin/ort?bundesland=${config.BUNDESLAND}`);
    const ort = response.data.map((row) => {
      return ({ value: row.ort, label: row.ort })
    });
    setChoicesOrt(ort);
  };

  useEffect(() => {
    getChoices();
  }, []);

  return (
    <Formik
      initialValues={{
        art: null,
        ort: [],
        bundesland: [{ value: config.BUNDESLAND, label: config.BUNDESLAND }],
        regierungsbezirk: [],
        landkreis: [],
        naturraum_hauptname: [],
        naturraum_name: [],
        tk25: [],
        fundort: [],
        praxisgruppe: [],
        stadium: [],
        art_der_beobachtung: [],
        beleg: [],
        bestimmungssicherheit: [],
        bestimmungsmethode: [],
        erfasser: [],
        beobachter: [],
        datenquelle: [],
        jahr_min: "",
        jahr_max: "",
        monat: [],
        datum_von: null,
        datum_bis: null,
        created_von: null,
        created_bis: null
      }}

      onSubmit={
        (values, { resetForm }) => {
          let jahr_min = values.jahr_min === "" ? 1800 : values.jahr_min;
          let jahr_max = values.jahr_max === "" ? dayjs().year() : values.jahr_max;
          let datum_von = values.datum_von === null ? '1800-01-01' : dayjs(values.datum_von).format("YYYY-MM-DD");
          let datum_bis = values.datum_bis === null ? new Date() : values.datum_bis;
          let created_von = values.created_von === null ? '1800-01-01' : dayjs(values.created_von).format("YYYY-MM-DD");
          let created_bis = values.created_bis === null
            ? dayjs().add(1, 'day').format("YYYY-MM-DD")
            : dayjs(values.created_bis).add(1, 'day').format("YYYY-MM-DD");

          props.handleSubmit({
            art: values.art !== null ? [values.art] : [], // values.art is an Object
            ort: values.ort,
            bundesland: values.bundesland,
            regierungsbezirk: values.regierungsbezirk,
            landkreis: values.landkreis,
            naturraum_hauptname: values.naturraum_hauptname,
            naturraum_name: values.naturraum_name,
            tk25: values.tk25,
            fundort: values.fundort,
            praxisgruppe: values.praxisgruppe,
            stadium: values.stadium,
            art_der_beobachtung: values.art_der_beobachtung,
            beleg: values.beleg,
            bestimmungssicherheit: values.bestimmungssicherheit,
            bestimmungsmethode: values.bestimmungsmethode,
            erfasser: values.erfasser,
            beobachter: values.beobachter,
            datenquelle: values.datenquelle,
            jahr: [jahr_min, jahr_max],
            monat: values.monat,
            datum: [datum_von, dayjs(datum_bis).format("YYYY-MM-DD")],
            created_at: [created_von, created_bis]
          });
        }
      }
    >
      {
        props => {
          const {
            values,
            // handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Row className='justify-content-md-left'>
                <Form.Group as={Col} xs={12} sm={12} md={4} lg={4} className="mb-2">
                  <InputArt name="art" onChange={handleSubmit}></InputArt>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={4} className="mb-2">
                  <InputSelect
                    name="ort"
                    title={false}
                    menuPortalTarget={document.body}
                    placeholder="Ort"
                    choices={choicesOrt}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} sm={6} md={2} className='d-md-block mb-2'>
                  <Dropdown onSelect={(open) => console.log(open)}>
                    <Dropdown.Toggle className="w-100" variant="secondary">
                      Mehr Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="dropdown-wide">
                        <div style={{ padding: "0 10px" }}>
                          <Row>
                            <Form.Group as={Col} md="4" className="dropdown-filter-column-left">
                              <InputSelect
                                name="bundesland"
                                choices={choicesBundesland}
                              />
                              <InputSelect
                                name="regierungsbezirk"
                                choices={choicesRegierungsbezirk}
                              />
                              <InputSelect
                                name="landkreis"
                                choices={choicesLandkreise}
                              />
                              <InputSelect
                                name="naturraum_hauptname"
                                title="Naturraum Hauptname"
                                choices={choicesNaturraumHauptname}
                              />
                              <InputSelect
                                name="naturraum_name"
                                title="Naturraum"
                                choices={choicesNaturraum}
                              />
                              <InputSelect
                                name="tk25"
                                title="TK25"
                                choices={choicesTK25}
                              />
                              <InputSelect
                                name="fundort"
                                choices={choicesFundort}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="dropdown-filter-column-left">
                              <InputSelect
                                name="praxisgruppe"
                                choices={choicesPraxisgruppe}
                              />
                              <InputSelect
                                name="stadium"
                                choices={choicesStadium}
                              />
                              <InputSelect
                                name="art_der_beobachtung"
                                choices={choicesArtDerBeobachtung}
                                title="Art der Beobachtung"
                              />
                              <InputSelect
                                name="beleg"
                                choices={choicesBeleg}
                              />
                              <InputSelect
                                name="bestimmungssicherheit"
                                choices={choicesBestimmungssicherheit}
                              />
                              <InputSelect
                                name="bestimmungsmethode"
                                choices={choicesBestimmungsmethode}
                              />
                              <InputSelect
                                name="datenquelle"
                                choices={choicesDatenquelle}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                              <InputSelect
                                name="erfasser"
                                choices={choicesErfasser}
                              />
                              <InputSelect
                                name="beobachter"
                                choices={choicesBeobachter}
                              />
                              <Form.Text>Jahr</Form.Text>
                              <Row>
                                <Form.Group as={Col} md="6">
                                  <YearInput
                                    id="jahr_min"
                                    value={values.jahr_min}
                                    placeholder="Jahr von"
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="ps-0">
                                  <YearInput
                                    id="jahr_max"
                                    value={values.jahr_max}
                                    placeholder="Jahr bis"
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                  />
                                </Form.Group>
                              </Row>
                              <InputSelect
                                name="monat"
                                choices={choicesMonate}
                              />
                              <Form.Text>Datum der Beobachtung</Form.Text>
                              <Row>
                                <Form.Group as={Col} md="6">
                                  <DatePickerField
                                    name="datum_von"
                                    placeholderText="Datum von..."
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="ps-0">
                                  <DatePickerField
                                    name="datum_bis"
                                    placeholderText="Datum bis..."
                                  />
                                </Form.Group>
                              </Row>
                              <Form.Text>Hinzugefügt am</Form.Text>
                              <Row>
                                <Form.Group as={Col} md="6">
                                  <DatePickerField
                                    name="created_von"
                                    placeholderText="Datum von..."
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="ps-0">
                                  <DatePickerField
                                    name="created_bis"
                                    placeholderText="Datum bis..."
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </Row>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
                <Form.Group as={Col} xs={6} sm={6} md={2} className="d-md-block mb-2">
                  <Button variant="success" type="submit" className="w-100">Daten abfragen</Button>
                </Form.Group>
              </Row>
            </Form>
          );
        }
      }
    </Formik>
  )
}

export default FiltersAdmin;
